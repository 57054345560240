@import url('https://fonts.googleapis.com/css?family=Poppins|Ubuntu&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0
}

body {
    background: #eee;
    font-family: 'Ubuntu', sans-serif
}

.box {
    position: relative;
    width: 100%;
    padding-right: 15px;

}

.our-services {
    margin-top: 20px;
    padding-bottom: 60px;
    padding: 50px 40px;
    min-height: 198px;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    transition: all .4s ease-in-out;
    box-shadow: 0 0 25px 0 rgba(20, 27, 202, .06)
}

.our-services .icon {
    padding-bottom: 60px;
    transform: translateY(-50%);
    text-align: center
}

.our-services:hover h3,
.our-services:hover p {
    color: #fff
}

.wrapper:hover {
    box-shadow: 0 0 25px 0 rgba(20, 27, 201, .01);
    cursor: pointer;
    background-image:linear-gradient(-45deg, #0d4386 30%, gray 150%);
    color: white;
    
}


a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: inherit;
}